<template>
  <div>
      <div class="carousel-form">
            <!-- <el-input v-model="word" style="width:200px"></el-input> <el-button type="primary" @click="init()">检索</el-button> -->
            <el-button type="primary"  @click="addCarousel">新增</el-button>
      </div>
      <div class="carousel-table">
            <el-table
              :data="list"
              border
              style="width: 100%">
              <!-- <el-table-column
                type="index">
              </el-table-column> -->
              <el-table-column label="序列" width="70">
                <template slot-scope="scope">
                      <span>{{(currentPg-1)*pageSize+scope.$index+1}}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="img"
                label="图片"
                width="300">
                <template slot-scope="scope">
                  <img :src="scope.row.img" alt="" style="max-width:300px;height:auto;">
                </template>
              </el-table-column>
              <el-table-column
                prop="info"
                label="轮播信息"
                width="300">
                <template slot-scope="scope">
                  <span style="white-space: normal;">{{scope.row.info}}</span>
                </template>
              </el-table-column>
              <!-- <el-table-column
                prop="articleTitle"
                label="被关联文章"
                width="230">
              </el-table-column> -->
              <!-- <el-table-column
                prop="isUse"
                label="是否已启用"
                width="230">
                <template slot-scope="scope">
                  {{scope.row.isUse==1?"是":"否"}}
                 </template>
              </el-table-column> -->
              <el-table-column
                label="操作"
                width="180">
                <template slot-scope="scope">
                  <el-button @click="edit(scope.row)" type="text" size="small">替换</el-button>
                  <el-button @click="deleteCarousel(scope.row)" type="text" size="small">删除</el-button>
                  <!-- <el-button @click="isUser(scope.row)" type="text" size="small">{{ (scope.row.isUse=="0"?"启用":"停用") }}</el-button> -->
                  <!-- <el-button @click="relation(scope.row)"  type="text" size="small">关联文章</el-button> -->
                </template>
              </el-table-column>
            </el-table>
      </div>
      <div style="position:relative;width:100%;height:30px;padding-top:20px">  
          <el-pagination style="position:absolute;right:0"
              @size-change="handleSizeChange"
              @current-change="currentPage"
              :current-page="currentPg"
              :page-sizes="[5, 10, 15]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total">
          </el-pagination>
      </div>
  </div>
  
</template>

<script>
import {QUERY,UPDATE,DELETE, QUERYED}  from '@/services/dao.js';
export default {    
    data(){
        return {
            centerDialogVisible:false,
          //   list: [{
          //       id: 2,
          //       img: 'https://car2.autoimg.cn/cardfs/product/g27/M04/CE/0A/1024x0_1_q95_autohomecar__ChsEfF1cCLqAf3FFAArPJeOpUBI092.jpg',
          //       info:"fdsfsdfdsfds",
          //       articleId:1,
          //       articleTitle:"存储存储错错",
          //       isUse:"是"
          // }],
           list:[],
           currentPg:1, //当前页初始值为1 
           pageSize:5,
           total:800,
           word:""
        }
    },
    created(){
      this.init();
    },
    methods: {
      showScope(scope){
        console.log(scope);
      },
        init(){
          console.log("被调用了")
            this.searchInfo(this.pageSize,0);
            // this.searchInfo(this.pageSize,(this.currentPg-1)*this.pageSize);
        },
        // 替换
        edit(row){
          this.$router.push({path: "/editCarouselMain",query:{id:row.id,content:row.info,img:row.img,type:1}});
           console.log(row);
        },
        relation(row){
          
          this.$router.push({path: "/relationCarouselMain",query:{id:row.id,articleId: (row.articleId>=0?row.articleId:"-1")}});
        },
        handleClick(row) {
            console.log(row);
        },
        addCarousel(){
            this.$router.push({path:"/addCarouselMain",query:{type: 1}});
        },
        //删除轮播图
       async deleteCarousel(row){
          
        let info=  await  DELETE("post","",'delete_TtCarousel(where: {id: {_eq: '+row.id+'}}) {     affected_rows   }');
           if(info.data.delete_TtCarousel.affected_rows>0){
              this.searchInfo(this.pageSize,(this.currentPg-1)*this.pageSize);
              // this.$forceUpdate();
              // this.init();
              this.$message({
                message:"删除成功!",
                type: 'success',
                duration: 1200
                });
           }
        },
        //是否已使用
      //  async isUser(row){
      //      let info=null;
      //      info=  await  UPDATE("post","",'  update_TtCarousel(where: {id: {_eq: '+row.id+'}}, _set: {isUse: '+(row.isUse==0?1:0)+'}) {     affected_rows   }');
      //      this.list.forEach((element,index )=> {
      //        if(element.id==row.id){
      //          this.list[index].isUse=row.isUse==0?1:0
      //        }
      //      });
      //   },
        async  searchInfo(limit,offset){
            this.list = [];
            // let info=  await  QUERY("post","",'	Rotation(limit: '+limit+', offset: '+offset+', where: { 		content: { 			_like: "%'+this.word+'%" 		} 	}) { 		articleId 		articleTitle 		content 		id 		img 		orderNum 		isUse 	} 	Rotation_aggregate(where: { 		content: { 			_like: "%'+this.word+'%"  		} 	}) { 		aggregate { 			count 		} 	}  ');
            let info = await QUERYED("post","",'TtCarousel(limit: '+limit+', offset: '+offset+', where: {type:{_eq:'+1+'}}) {id img content} TtCarousel_aggregate(where:{type:{_eq:'+1+'}}) {aggregate { count }} ')
            console.log(info);
            let count = info.data.TtCarousel_aggregate.aggregate.count;
            this.total=count;
            for(let i=0;i<info.data.TtCarousel.length;i++){
              this.list.push({
                id:info.data.TtCarousel[i].id,
                img:this.api.LoginURL.concat(info.data.TtCarousel[i].img),
                info:info.data.TtCarousel[i].content,
                orderNum:info.data.TtCarousel[i].orderNum,
                articleTitle:info.data.TtCarousel[i].articleTitle,
                isUse:info.data.TtCarousel[i].isUse
               });
            }
        },
        //前一页
        prevPage(val){
            console.log(val);
            this.currentPg=val;
            console.log("this.currentPg: ",this.currentPg);
            this.searchInfo(this.pageSize,(this.currentPg-1)*this.pageSize);
        },
        //下一页
        nextPage(val){
            console.log(val);
            this.currentPg=val;
            console.log("this.currentPg: ",this.currentPg);
            this.searchInfo(this.pageSize,(this.currentPg-1)*this.pageSize);
        },
        //当前页
        currentPage(val){
            console.log(val);
            this.currentPg=val;
            console.log("this.currentPg: ",this.currentPg);
            this.searchInfo(this.pageSize,(this.currentPg-1)*this.pageSize);
        },
        //切换每页条数
        handleSizeChange(val){
            this.pageSize=val;
           this.searchInfo(this.pageSize,(this.currentPg-1)*this.pageSize);
        }
    }
}
</script>

<style scoped>
 .carousel-table{
     margin-top: 20px;
 }
</style>